body {
  background-color: #E5E5E5;
}

.App {
  text-align: center;
  min-height: 100vh;

  height: 100%;
}

.App-header {
  min-height: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: calc(100vh - 100px);

}

.App-title {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 48px;
  margin-top: 20px;
}

.App-explain {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-top: 20px;
}

.App-button-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 24px;
  margin: auto;
  color: 'white'
}

.App-try-button {
  display: flex;
  width: 180px;
  background-color: #33333D;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.App-try-button-bg {
  color: white;
}

/* Unvisited link  */
.App-try-button-bg:visited {
  color: white;
}

/* Visited link    */
.App-try-button-bg:hover {
  color: white;
}

/* Mouse over link */
.App-try-button-bg:active {
  color: white;
}

/* Selected link   */


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}